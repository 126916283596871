import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import { openModal } from 'shared/components/Modal/actions';
import Auth from '@aws-amplify/auth';
import {
  splitHistoryDataByTypes,
  formatOrderHistoryData,
} from './actionsUtils';
import { statusMap } from '../components/OrderStatusLineItem/orderStatusUtils';

export const SET_REDIRECT_ON_PERSONAL_PROMO_PAGE = 'SET_REDIRECT_ON_PERSONAL_PROMO_PAGE';
export const SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE = 'SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE';
export const SET_REDIRECT_ON_RESET_PASSWORD_PAGE = 'SET_REDIRECT_ON_RESET_PASSWORD_PAGE';
export const LOADING_PERSONALIZED_PROMOTIONS = 'LOADING_PERSONALIZED_PROMOTIONS';
export const RESOLVED_PERSONALIZED_PROMOTIONS = 'RESOLVED_PERSONALIZED_PROMOTIONS';
export const REJECTED_PERSONALIZED_PROMOTIONS = 'REJECTED_PERSONALIZED_PROMOTIONS';
export const NO_PERSONALIZED_PROMOTIONS_AVAILABLE = 'NO_PERSONALIZED_PROMOTIONS_AVAILABLE';
export const LOADING_ORDER_HISTORY_DATA = 'LOADING_ORDER_HISTORY_DATA';
export const RESOLVED_ORDERHISTORY_DATA = 'RESOLVED_ORDERHISTORY_DATA';
export const REJECTED_ORDERHISTORY_DATA = 'REJECTED_ORDERHISTORY_DATA';
export const NO_ORDERHISTORY_DATA = 'NO_ORDERHISTORY_DATA';
export const NO_ORDERHISTORY_DATA_AVAILABLE = 'NO_ORDERHISTORY_DATA_AVAILABLE';
export const RESOLVED_CCPA_FORM = 'RESOLVED_CCPA_FORM';
export const REJECTED_CCPA_FORM = 'REJECTED_CCPA_FORM';
export const RESOLVED_RECAPTCHA = 'RESOLVED_RECAPTCHA';
export const REJECTED_RECAPTCHA = 'REJECTED_RECAPTCHA';
export const RECAPTCHA_SITE = 'RECAPTCHA_SITE';
export const REJECTED_SEND_EMAIL = 'REJECTED_SEND_EMAIL';
export const RESOLVED_SEND_EMAIL = 'RESOLVED_SEND_EMAIL';
export const LOADING_RESET_PASSWORD = 'LOADING_RESET_PASSWORD';
export const RESOLVED_RESET_PASSWORD = 'RESOLVED_RESET_PASSWORD';
export const REJECTED_RESET_PASSWORD = 'REJECTED_RESET_PASSWORD';
export const SET_MFA_AUTH_USER = 'SET_MFA_AUTH_USER';
export const LOGIN_PAGES_UTAG_DATA = 'LOGIN_PAGES_UTAG_DATA';
export const REGISTER_PAGE_UTAG_DATA = 'REGISTER_PAGE_UTAG_DATA';
export const CCPA_UTAG_DATA = 'CCPA_UTAG_DATA';
export const RESET_PW_PAGELOAD_UTAG_DATA = 'RESET_PW_PAGELOAD_UTAG_DATA';
export const RESOLVED_COMBINED_ORDER_HISTORY = 'RESOLVED_COMBINED_ORDER_HISTORY';
export const SET_GUEST_ORDER_HISTORY = 'SET_GUEST_ORDER_HISTORY';
export const GUEST_HISTORY_UTAG = 'GUEST_HISTORY_UTAG';
export const GUEST_DETAILS_UTAG = 'GUEST_DETAILS_UTAG';
export const ORDER_HISTORY_UTAG = 'ORDER_HISTORY_UTAG';
export const ORDER_HISTORY_PROFILE_UTAG = 'ORDER_HISTORY_PROFILE_UTAG';
export const MAP_OH_ITEM_ADDRESSES = 'MAP_OH_ITEM_ADDRESSES';
export const SET_RAW_OH_DATA = 'SET_RAW_OH_DATA';
export const SET_FETCHED_ALL_OH = 'SET_FETCHED_ALL_OH';
export const SET_FETCHED_ALL_ONLINE = 'SET_FETCHED_ALL_ONLINE';
export const SET_FETCHED_ALL_STORE = 'SET_FETCHED_ALL_STORE';
export const LOADING_CANCELLED_ORDERS = 'LOADING_CANCELLED_ORDERS';
export const LOADING_ONLINE_ORDERS = 'LOADING_ONLINE_ORDERS';
export const LOADING_STORE_ORDERS = 'LOADING_STORE_ORDERS';
export const SET_CANCELLED_ORDER_HISTORY = 'SET_CANCELLED_ORDER_HISTORY';
export const SET_ONLINE_ORDER_HISTORY = 'SET_ONLINE_ORDER_HISTORY';
export const SET_STORE_ORDER_HISTORY = 'SET_STORE_ORDER_HISTORY';
export const DT_LOGIN_FAILURE = 'DT_LOGIN_FAILURE';
export const REJECTED_CANCELLED_ORDER_HISTORY = 'REJECTED_CANCELLED_ORDER_HISTORY';
export const REJECTED_ONLINE_ORDER_HISTORY = 'REJECTED_ONLINE_ORDER_HISTORY';
export const REJECTED_STORE_ORDER_HISTORY = 'REJECTED_STORE_ORDER_HISTORY';
export const REJECTED_ORDER_HISTORY = 'REJECTED_ORDER_HISTORY';
export const SET_SELECTED_REPLENISHABLE = 'SET_SELECTED_REPLENISHABLE';
export const SET_OH_SEARCH_TEXT = 'SET_OH_SEARCH_TEXT';
export const START_SEARCHING_OH = 'START_SEARCHING_OH';
export const FAVORITE_ITEMS_UTAG = 'FAVORITE_ITEMS_UTAG';
export const RESOLVED_STYLED_FOR_YOU_DATA = 'RESOLVED_STYLED_FOR_YOU_DATA';
export const REJECTED_STYLED_FOR_YOU_DATA = 'REJECTED_STYLED_FOR_YOU_DATA';
export const CLICKED_STYLED_FOR_YOU_UTAG = 'CLICKED_STYLED_FOR_YOU_UTAG';

export function setRedirectURL(targetURL, sourcePageType) {
  return (dispatch) => {
    let redirectFromPage = '';
    if (sourcePageType === 'specialoffers') {
      redirectFromPage = SET_REDIRECT_ON_PERSONAL_PROMO_PAGE;
    } else if (sourcePageType === 'accountoverview') {
      redirectFromPage = SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE;
    } else if (sourcePageType === 'resetpassword') {
      redirectFromPage = SET_REDIRECT_ON_RESET_PASSWORD_PAGE;
    }
    dispatch({
      type: redirectFromPage,
      payload: { targetURL, redirectType: 302 },
    });
  };
}

export function openRegisterEmailErrorModal() {
  return (dispatch) => dispatch(
    openModal({
      type: 'RegisterEmailErrorModal',
      className: 'register-email-modal',
    })
  );
}

export function openForgotPasswordModal(modalClassName, data) {
  return (dispatch) => dispatch(
    openModal({
      type: 'ForgotPasswordModalDT',
      className: modalClassName,
      props: {
        email: data?.email,
        modalClassName,
        isOrderHistPage: data?.isOrderHistPage,
      },
    })
  );
}

export function getOrderHistoryData(
  jwtToken,
  username,
  {
    sort = {}, filter = {}, pagination = {}, search = '',
  } = {},
  from = 0, isOnlineTab = false, isStoreTab = false, // eslint-disable-line
  orderHistoryV2Toggle = false
) {
  const size = 50;

  function handleOrderHistory(response, state, dispatch) {
    const res = {
      data: orderHistoryV2Toggle ? response?.data?.orderItems : response?.data,
    };
    if (res.data.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_OH,
        payload: true,
      });
    }

    dispatch({
      type: SET_OH_SEARCH_TEXT,
      payload: search,
    });

    const allData = [
      ...state.profile.orderHistory.pagination.rawOrderHistoryData,
      ...res.data,
    ];
    dispatch({
      type: SET_RAW_OH_DATA,
      payload: allData,
    });
    const hasCancelled = allData.some((item) => statusMap[item.orderStatus]?.startsWith('Cancel'));
    const mergeReturnsToggle = get(state, 'toggles.OH_MERGE_RETURNS', false);
    const dataObj = formatOrderHistoryData(allData, mergeReturnsToggle);
    const count = state.profile.orderHistory.orderCounts.all + res.data.length;
    return dispatch({
      type: RESOLVED_COMBINED_ORDER_HISTORY,
      payload: { orders: splitHistoryDataByTypes(dataObj), hasCancelled, count },
    });
  }

  function handleCancelledOrders(response, state, dispatch) {
    const res = {
      data: orderHistoryV2Toggle ? response?.data?.orderItems : response?.data,
    };
    const mergeReturnsToggle = get(state, 'toggles.OH_MERGE_RETURNS', false);
    const formattedData = formatOrderHistoryData(res.data, mergeReturnsToggle);
    dispatch({ type: SET_CANCELLED_ORDER_HISTORY, payload: formattedData });
  }
  function handleOnlineOrders(response, state, dispatch) {
    const res = {
      data: orderHistoryV2Toggle ? response?.data?.orderItems : response?.data,
    };
    if (res.data.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_ONLINE,
        payload: true,
      });
    }
    const formattedData = formatOrderHistoryData(res.data);
    const onlineData = [
      ...state.profile.orderHistory.onlineOrders,
      ...formattedData,
    ];

    const count = state.profile.orderHistory.orderCounts.online + res.data.length;
    return dispatch({ type: SET_ONLINE_ORDER_HISTORY, payload: onlineData, count });
  }
  function handleStoreOrders(response, state, dispatch) {
    const res = {
      data: orderHistoryV2Toggle ? response?.data?.orderItems : response?.data,
    };
    if (res.data.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_STORE,
        payload: true,
      });
    }
    const formattedData = formatOrderHistoryData(res.data);
    const storeData = [
      ...state.profile.orderHistory.storeOrders,
      ...formattedData,
    ];
    const count = state.profile.orderHistory.orderCounts.store + res.data.length;
    return dispatch({ type: SET_STORE_ORDER_HISTORY, payload: storeData, count });
  }
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 6000, true);
    Auth.currentAuthenticatedUser().then((user) => {
      // eslint-disable-next-line
      const userId = user?.attributes.preferred_username;
      const jwtTokenId = user?.signInUserSession.idToken.jwtToken;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtTokenId}`,
      };

      const togglesObj = {
        connect: true,
        'in-store': get(state, 'toggles.OH_STORE_ORDERS', false),
      };
      const filterArr = ['connect', 'in-store'].filter(
        (filter) => togglesObj[filter]
      );
      const isCancelled = filter.status === 'CX';
      if (filterArr.length !== 2 && (!isOnlineTab && !isStoreTab && !isCancelled)) {
        filter.channels = [...filterArr, 'online'];
      }
      // eslint-disable-next-line
      const key = isOnlineTab? 'online': (isStoreTab? 'store': 'all');

      const body = {
        sort: {
          sortType: 'desc',
          ...sort,
        },
        pagination: {
          from: state.profile.orderHistory.orderCounts[key],
          size,
          ...pagination,
        },
        filter: {
          ...filter,
        },
      };

      if (search) body.searchText = search;


      const loadingAction = () => {
        if (isCancelled) {
          return { type: LOADING_CANCELLED_ORDERS, payload: true };
        }
        if (isOnlineTab) {
          return { type: LOADING_ONLINE_ORDERS, payload: true };
        }

        if (isStoreTab) {
          return { type: LOADING_STORE_ORDERS, payload: true };
        }
        return { type: LOADING_ORDER_HISTORY_DATA, payload: true };
      };

      dispatch(loadingAction());

      const url = orderHistoryV2Toggle ? `${NMConfig.API_DT_ORDER_HISTORY_V2}/profiles/${userId}/orders` : `${NMConfig.API_DT_ORDER_HISTORY}/${userId}/orders`;

      return requestApi
        .post(url, body, {
          headers,
        })
        .then((res) => {
          if (isCancelled) {
            return handleCancelledOrders(res, state, dispatch);
          }
          if (isOnlineTab) {
            return handleOnlineOrders(res, state, dispatch);
          }

          if (isStoreTab) {
            return handleStoreOrders(res, state, dispatch);
          }

          return handleOrderHistory(res, state, dispatch);
        })
        .catch(() => {
          const type = isCancelled
            ? REJECTED_CANCELLED_ORDER_HISTORY
            : REJECTED_ORDER_HISTORY;
          dispatch({ type });
        });
    }).catch(() => {
    });
  };
}

export function mapAddressesToItems(mappedOrders) {
  return (dispatch, getState) => {
    const state = getState();
    const orders = { ...state.profile.orderHistory.combinedOrderHistory };
    const orderIndex = orders.allOrders.findIndex(
      (order) => order[0].formattedOrderId === mappedOrders.formattedOrderId
    );
    for (let i = 0; i < mappedOrders.orderDetails.length; i++) {
      for (let j = 0; j < mappedOrders.orderDetails[i].items.length; j++) {
        const mappedItem = mappedOrders.orderDetails[i].items[j];
        for (let k = 0; k < orders.allOrders[orderIndex].length; k++) {
          const browseItem = orders.allOrders[orderIndex][k];
          if (
            mappedItem.cmosCatalogItem === browseItem.item.cmosCatalogItem
            && mappedItem.status === browseItem.orderStatus
            && (mappedItem.trackingLink === browseItem.trackingLink
              || (!mappedItem.trackingLink && !browseItem.trackingLink))
          ) {
            browseItem.shippingAddress = mappedOrders.orderDetails[i].shippingAddress;
          }
        }
      }
    }
    dispatch({
      type: MAP_OH_ITEM_ADDRESSES,
      payload: orders,
    });
  };
}

export function submitCcpaForm(ccpaFormData, errorCallback) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(NMConfig.API_SUBMIT_CCPA_FORM, ccpaFormData)
      .then((resObj) => {
        dispatch({
          type: RESOLVED_CCPA_FORM,
          payload: resObj.data,
        });
        return resObj.data;
      })
      .catch((e) => {
        if (errorCallback) {
          errorCallback(e);
        }
        dispatch({
          type: REJECTED_CCPA_FORM,
        });
      });
  };
}

export function validateReCaptcha(captchaValue, resetCaptcha) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(NMConfig.API_RECAPTCHA_VERIFY, { captchaValue })
      .then((resObj) => {
        if (resObj.data && resObj.data.success === true) {
          return dispatch({
            type: RESOLVED_RECAPTCHA,
            payload: resObj.data.success,
          });
        }
        resetCaptcha();
        dispatch({
          type: REJECTED_RECAPTCHA,
        });
        return resObj.data;
      })
      .catch(() => {
        resetCaptcha();
        dispatch({
          type: REJECTED_RECAPTCHA,
        });
      });
  };
}

export function sendForgotPwEmail(email, successCallback, errorCallback) {
  return (dispatch, getState) => {
    const state = getState();
    const apiTimeout = get(state, 'apiTimeouts.FORGOT_PW_API_TIMEOUT', 5000);
    const requestApi = httpWithLogging(state, apiTimeout, true);
    return requestApi
      .post(NMConfig.API_SEND_FORGOT_PW_EMAIL, { email })
      .then((resObj) => {
        if (resObj) {
          dispatch({
            type: RESOLVED_SEND_EMAIL,
            payload: { email, successMessage: resObj.data },
          });
          return successCallback();
        }
        return errorCallback();
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'The profile is not confirmed. Please confirm first.') {
          window.location.href = `/my/verify-email?id=${btoa(email)}`;
        }
        errorCallback();
        dispatch({
          type: REJECTED_SEND_EMAIL,
        });
      });
  };
}
export function fetchStyledForYouData(variantId = '', productId, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 6000, true);
    const stylyzeUrl = `${NMConfig.API_PRODUCT_OUTFITTING_CLIENT}?accId=59ba93a4e4b0e46ea98f0759&variantIds=${variantId}&page=0&pageSize=3&minimal=true`;

    return requestApi
      .get(stylyzeUrl)
      .then((response) => {
        const data = response?.data?.csbList;
        const stylyzeData = Array.isArray(data) ? data : [];

        if (stylyzeData?.length >= 1) {
          callback();
          dispatch({
            type: RESOLVED_STYLED_FOR_YOU_DATA,
            payload: { [productId]: stylyzeData },
          });
        } else {
          dispatch({
            type: RESOLVED_STYLED_FOR_YOU_DATA,
            payload: { [productId]: 'Not Available' },
          });
        }
      })
      .catch(() => dispatch({
        type: REJECTED_STYLED_FOR_YOU_DATA,
        payload: { [productId]: 'Not Available' },
      }));
  };
}
export const sendCCPAUtagDataOnPageload = () => ({ type: CCPA_UTAG_DATA });
export const sendResetPwPageUtagDataOnPageload = () => ({
  type: RESET_PW_PAGELOAD_UTAG_DATA,
});
export const sendLoginPagesUtagDataOnPageload = () => ({
  type: LOGIN_PAGES_UTAG_DATA,
});
export const loadRegisterPageUtagData = () => ({
  type: REGISTER_PAGE_UTAG_DATA,
});
export const loadGuestOrderPageUtagData = (eventName = null, orderType = undefined) => ({
  type: GUEST_HISTORY_UTAG,
  payload: { eventName, orderType },
});
export const loadGuestOrderDetailsUtagData = (orderType) => ({
  type: GUEST_DETAILS_UTAG,
  payload: { orderType },
});
export const loadOrderHistoryPageUtagData = () => ({
  type: ORDER_HISTORY_UTAG,
});
export const loadOrderHistoryProfileUtagData = (payload) => ({
  type: ORDER_HISTORY_PROFILE_UTAG,
  payload,
});
export const loadFavoriteItemsPageUtagData = () => ({
  type: FAVORITE_ITEMS_UTAG,
});
export const loginFailureUtagData = (payload) => ({ type: DT_LOGIN_FAILURE, payload });

export const clickedStyledForYouUtagData = () => ({ type: CLICKED_STYLED_FOR_YOU_UTAG });

export const resetPassword = (requestBody, successCallback, errorCallback) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_RESET_PASSWORD });

  const state = getState();
  const requestApi = httpWithLogging(state, 5000, true);
  const endpoint = NMConfig.API_RESET_PASSWORD;

  return requestApi
    .post(endpoint, requestBody)
    .then((resObj) => {
      if (resObj) {
        dispatch({
          type: RESOLVED_RESET_PASSWORD,
          payload: resObj.data,
        });
        return successCallback();
      }
      return errorCallback();
    })
    .catch((e) => {
      dispatch({
        type: REJECTED_RESET_PASSWORD,
      });
      return errorCallback(e?.response?.data);
    });
};

export function setGuestOrderHistory(orderHistory) {
  return {
    type: SET_GUEST_ORDER_HISTORY,
    payload: orderHistory,
  };
}

export function setMfaUser(user) {
  return {
    type: SET_MFA_AUTH_USER,
    payload: user,
  };
}

export function setSelectedReplenishable(replenItem) {
  return {
    type: SET_SELECTED_REPLENISHABLE,
    payload: replenItem,
  };
}

export function startSearching() {
  return {
    type: START_SEARCHING_OH,
  };
}
